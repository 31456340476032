/* eslint-disable */
export default {
	login: {
		slogan: '高性能',
		describe: 'Vue 3+Element-Palusによる中バックグラウンドの先端解決策。',
		signInTitle: 'ユーザー登録',
		rememberMe: '覚えてください',
		forgetPassword: 'パスワードを忘れます',
		signIn: 'ログイン',
		signInOther: 'その他のログイン方式',
		userPlaceholder: 'ユーザ名/携帯電話/メールアドレス',
		userError: 'ユーザ名を入力してください',
		PWPlaceholder: 'パスワードを入力してください',
		PWError: 'パスワードを入力してください',
		admin: '管理者',
		user: 'ユーザー'
	},
	user: {
		dynamic: 'ダイナミック',
		info: 'ユーザー',
		settings: '設定',
		nightmode: 'ナイトモード',
		nightmode_msg: '光の弱い環境に適しています，現在のブラックモードはベータバージョンです。',
		language: '言語',
		language_msg: '翻訳が進行中、本ビューのテキストを一時的に翻訳しました。',
		password: 'パスワード'
	}
}
/* eslint-enable */