import { createRouter, createWebHistory } from 'vue-router';
import api from '@/api'
import store from '@/store'
import tool from '@/utils/tool'


// 系统路由
const routes = [{
    path: "/",
    component: () => import(/* webpackChunkName: "layout" */ '@/layout'),
    redirect: '/home',
    children: [{
        path: '/home',
        component: () => import(/* webpackChunkName: "home" */ '@/views/home')
    }, {
        path: '/news',
        component: () => import(/* webpackChunkName: "news" */ '@/views/news')
    }, {
        path: '/newsdetail',
        component: () => import(/* webpackChunkName: "help" */ '@/views/news/detail')
    }, {
        path: '/activity',
        component: () => import(/* webpackChunkName: "activity" */ '@/views/activity')
    }, {
        path: '/activity/detail',
        component: () => import(/* webpackChunkName: "activity" */ '@/views/activity/detail')
    }, {
        path: '/help',
        component: () => import(/* webpackChunkName: "help" */ '@/views/help')
    }, {
        path: '/help/detail',
        component: () => import(/* webpackChunkName: "help" */ '@/views/help/detail')
    }, {
        path: '/help/details',
        component: () => import(/* webpackChunkName: "help" */ '@/views/help/details')
    }, {
        path: '/help/list',
        component: () => import(/* webpackChunkName: "help" */ '@/views/help/list')
    }, {
        path: '/coursecenter',
        component: () => import(/* webpackChunkName: "courseCenter" */ '@/views/courseCenter')
    }, {
        path: '/coursecenter/detail',
        component: () => import(/* webpackChunkName: "courseCenter" */ '@/views/courseCenter/detail')
    }, {
        path: '/offlinecenter',
        component: () => import(/* webpackChunkName: "offlineCenter" */ '@/views/offlineCenter')
    }, {
        path: '/offlinecenter/detail',
        component: () => import(/* webpackChunkName: "offlineCenter" */ '@/views/offlineCenter/detail')
    }, {
        path: '/liveCenter',
        component: () => import(/* webpackChunkName: "liveCenter" */ '@/views/liveCenter')
    }, {
        path: '/liveCenter/detail',
        component: () => import(/* webpackChunkName: "courseCenter" */ '@/views/liveCenter/detail')
    }, {
        path: '/setting',
        component: () => import(/* webpackChunkName: "courseCenter" */ '@/views/setting')
    }, {
        path: '/search',
        component: () => import(/* webpackChunkName: "courseCenter" */ '@/views/search')
    }, {
        path: '/about',
        component: () => import(/* webpackChunkName: "about" */ '@/views/about')
    }]
}, {
    path: "/sell_draft/:courseId-:contentId",
    component: () => import(/* webpackChunkName: "courseCenter" */ '@/views/outLinkCourse')
}, {
    path: "/sell/:courseId-:contentId",
    component: () => import(/* webpackChunkName: "login" */ '@/views/video')
},
{
    path: "/login",
    component: () => import(/* webpackChunkName: "404" */ '@/views/login/login')
}, {
    path: "/:pathMatch(.*)*",
    component: () => import(/* webpackChunkName: "404" */ '@/views/other/404')
}]

const router = createRouter({
    history: createWebHistory(),
    routes: routes
})

let queryInfo = false
router.beforeEach(async (to, from, next) => {
    document.documentElement.scrollTop = 0
    if (!queryInfo) {
        let tokenCompany = store.state.user.token_company
        let userToken = store.state.user.token

        try {
            if (tokenCompany) {
                await api.auth.companyuser.get().then(res => {
                    store.commit("SET_USER_INFO", null);
                    store.commit("SET_TOKEN", null);
                    tool.data.set("USER_INFO", null)
                    tool.data.set("TOKEN", null)

                    tool.data.set("USER_INFO_COMPANY", res.data)
                    tool.data.set("PERMISSIONS_COMPANY", res.data.permissions)
                    store.commit("SET_USER_INFO_COMPANY", res.data);
                    store.commit("SET_PERMISSIONS_COMPANY", res.data.permissions);
                    queryInfo = true
                })
            } else if (userToken) {
                await api.auth.user.get().then(res => {
                    tool.data.set("TOKEN_COMPANY", null)
                    tool.data.set("USER_INFO_COMPANY", null)
                    store.commit("SET_USER_INFO_COMPANY", null);
                    store.commit("SET_PERMISSIONS_COMPANY", null);

                    tool.data.set("USER_INFO", res.data)
                    store.commit("SET_USER_INFO", res.data);
                    queryInfo = true
                })
            }
        } catch (error) {
            console.log(error)
        }
    }
    next()
})


export default router