<template>
    <div class="pr bg-f empty-box" :style="`padding-top: ${ptNumber}px;padding-bottom: ${pbNumber}px;width:100%;position: relative;`">
        <div class="content-pos">
            <img :src="!icon ? require(`@/assets/png/wu.png`) : icon" class="m0a dk w200"  />
            <div class="f14 cor9 tc">{{ text }}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        className: {
            type: String,
            default: 'insaid_swiper'

        },
        dateType: { // 用于区分页面上不同的组件，不互相影响
            type: Number
        },
        ptNumber: {
            type: Number,
            default: 50
        },
        pbNumber: {
            type: Number,
            default: 60
        },
        year: {
            type: String
        },
        month: {
            type: String
        },
        day: {
            type: String
        },
        icon: {
            type: String
        },
        text: {
            type: String
        }
    }
}
</script>

<style lang="scss" scoped>
.pr {
    position: relative;
    height: 250px;
}

.pb60 {
    padding-bottom: 60px;
}

.m0a {
    margin: 0 auto;
}

.dk {
    display: block;
}

.f14 {
    font-size: 14px;
}

.cor9 {
    color: #999;
}

.tc {
    text-align: center;
}

.w200 {
    width: 200px;
}

.content-pos {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    margin: 0 auto;
    left:0; right:0;
}
</style>
