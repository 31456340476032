/* eslint-disable */
import tool from '@/utils/tool';
export default {
	state: {
		user_info: tool.data.get("USER_INFO") || '',
		token: tool.data.get("TOKEN") || '',
		token_company: tool.data.get("TOKEN_COMPANY") || '',
		user_info_company: tool.data.get("USER_INFO_COMPANY") || '',
		permissions_company: tool.data.get("PERMISSIONS_COMPANY") || '',
		user_company: tool.data.get("USER_INFO_COMPANY") || ''
	},
	mutations: {
		SET_TOKEN(state, key) {
			state.token = key
		},
		SET_TOKEN_COMPANY(state, key) {
			state.token_company = key
		},
		SET_USER_INFO(state, key) {
			state.user_info = key
		},
		SET_USER_COMPANY(state, key) {
			state.user_company = key
		},
		SET_USER_INFO_COMPANY(state, key) {
			state.user_info_company = key
		},
		SET_PERMISSIONS_COMPANY(state, key) {
			state.permissions_company = key
		}
	},
	action: {
		setUserInfo({ commit }, key) {
			commit('SET_USER_INFO', key)
		},
		setTokenCompany({ commit }, key) {
			commit('SET_TOKEN_COMPANY', key)
		},
		setToken({ commit }, key) {
			commit('SET_TOKEN', key)
		},
		setUserCompany({ commit }, key) {
			commit('SET_USER_COMPANY', key)
		},
		setUserInfoCompany({ commit }, key) {
			commit('SET_USER_INFO_COMPANY', key)
		},
		setPermissionsCompany({ commit }, key) {
			commit('SET_PERMISSIONS_COMPANY', key)
		}
	}
}
/* eslint-enable */