<template>
    <div class="jdjz wp100 hp100">
        <div>
            <div class="corf5"  v-if="!src || src.length === 0">音频格式转换中，请稍后播放！</div><br />
            <audio
                ref="audioPlayer"
                key="audio-show"
                controls
                :oncontextmenu="download ? 'return true' : 'return false'"
                controlsList="nodownload"
                preload="none"
                :src="src"
                type="video/mp3"
                >
            </audio>
        </div>
    </div>
</template>
<script>
export default {
    components: {},
    data() {
        return {
            player: null,
            timer: null,
            lastSavetime: 0,
            paryTime: 0
        }
    },
    props: {
        src: {
            type: String,
            default: () => {
                return ''
            }
        },
        start: {
            type: Number,
            default: () => {
                return 0
            }
        },
        icon: {
            type: String,
            default: () => {
                return ''
            }
        },
        status: {
            type: Number,
            default: () => {
                return 0
            }
        },
        download: {
            type: Boolean,
            default: () => {
                return true
            }
        }
    },
    watch: {
        src: {
            deep: true,
            handler() {
                if (this.player) {
                    this.player.load()
                } else {
                    this.$nextTick(function () {
                        this.initVideo();
                    })
                }
            }
        }
    },
    beforeUnmount() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    },
    mounted() {
        this.$nextTick(function () {
            this.initVideo();
        })
    },
    methods: {
        initVideo() {
            let that = this
            this.player = this.$refs.audioPlayer;
            this.player.src = this.src
            this.player.load()
            this.player.addEventListener('loadstart', () => {
                that.lastSavetime = 0
                that.paryTime = 0
                let _start = parseInt(that.start)
                if (isNaN(_start)) {
                    _start = 0
                }
                that.paryTime = _start * 60
                if (that.status === 2) {
                    that.paryTime = 10000000000 * 60; // 已学习完成的时候，用超大数字控制视频拖动范围
                    that.$refs.audioPlayer.currentTime = 0 // 学习完成后，初始状态从零开始
                } else {
                    // 学习中的视频，将视频重置到上次保存的播放时间的位置
                    that.$refs.audioPlayer.currentTime = that.paryTime
                }
            });

            this.player.addEventListener('timeupdate', () => {
                if (that.$refs.audioPlayer && that.$refs.audioPlayer.currentTime) {
                    if (that.$refs.audioPlayer.currentTime - that.paryTime > 1) {
                        that.$refs.audioPlayer.currentTime = that.paryTime;
                    } else if (that.$refs.audioPlayer.currentTime > that.paryTime) {
                        if (parseInt(that.paryTime) !== parseInt(that.$refs.audioPlayer.currentTime)) {
                            that.paryTime = that.$refs.audioPlayer.currentTime
                            if (parseInt(that.paryTime) % 60 === 0) {
                                that.lastSavetime = that.paryTime
                                that.$emit("progress", that.paryTime)
                            }
                        }
                        that.paryTime = that.$refs.audioPlayer.currentTime
                    }
                }
            })

            this.player.addEventListener('ended', (q) => {
                that.lastSavetime = that.$refs.audioPlayer.currentTime
                that.$emit("progress", that.$refs.audioPlayer.currentTime);
            });

            this.player.addEventListener('pause', (q) => {
                that.$emit('pause')
            });
            this.player.addEventListener('play', (q) => {
                let times = (parseInt(that.$refs.audioPlayer.currentTime) / 60 > 1) ? Math.floor((parseInt(that.$refs.audioPlayer.currentTime) / 60)) : 0
                if (times >= 6) {
                    that.pause()
                } else {
                    that.$emit('play')
                }
            });
        },
        pause() {
            // 停止播放并重置到上一次保存记录的时间
            this.player.pause();
        },
        pauseLast() {
            // 停止播放并重置到上一次保存记录的时间
            this.paryTime = this.lastSavetime - 2
            this.player.currentTime = this.lastSavetime - 2
            this.player.pause();
        },
        play() {
            // 停止播放并重置到上一次保存记录的时间
            this.player.play();
        }
    }
};
</script>
<style scoped>
.wp100 {
    width: 100%;
}

.hp100 {
    height: 100%;
}

.jdjz {
    display: flex;
    align-items: center;
    text-align:center;
    justify-content: center;
}

.corf5 {
    color:#F56C6C;
}
</style>

