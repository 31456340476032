<template>
    <el-dialog width="1000px" height="500px" :close-on-click-modal="false" v-model="visible" :title="'课件 - ' + info.name" :before-close="handleClose" @opened="dialogOpen" append-to-body>
        <div class="pr h500">
            <!-- 视频任务 -->
            <videos ref="playVideo" @play="play" @progress="mediaPlayProgress" v-if="info.fileType && (info.fileType.val === 1 || info.fileType.val === 5) && visible" :src="info.status && info.status.val == 1 ? info.previewFile[0] : ''" />
            <!-- 音频任务 -->
            <audios @play="play" @progress="mediaPlayProgress" v-if="info.fileType && (info.fileType.val === 2 || info.fileType.val === 6) && visible" ref="playVideo" :src="info.status && info.status.val == 1 ? info.previewFile[0] : ''" />
            <file v-if="info.fileType && info.fileType.val === 4 && visible" :url="info.status && info.status.val == 1 ? info.previewFile[0] : ''" />
            <div class="see-all" v-if="playshow && info.fileType && info.fileType.val === 1 && visible">
                试看6分钟，观看完整视频请购买课程<i class="el-icon-close cur ml10" @click="closes"></i>
            </div>
            <div class="see-alls" v-if="playshow && info.fileType && info.fileType.val === 2 && visible">
                试听6分钟，完整音频请购买课程<i class="el-icon-close cur ml10" @click="closes"></i>
            </div>
            <div class="cover-bg" v-if="endshow">
                <div class="flexHV-C">
                    <div class="flexV cur" @click="videopay">
                        <i class="el-icon-refresh-right cf mr5"></i>
                        <div class="cf">重播</div>
                    </div>
                    <div class="cf mt20" v-if="info.fileType && info.fileType.val === 1 && visible">
                        试看已结束，点击下方按钮观看全部课程~
                    </div>
                    <div class="cf mt20" v-if="info.fileType && info.fileType.val === 2 && visible">
                        试听已结束，点击下方按钮学习全部课程~
                    </div>
                    <div class="gobuy cur" @click="handleClose">购买</div>
                </div>
            </div>
        </div>
        <template #footer>
            <el-button @click="handleClose">关闭</el-button>
        </template>
    </el-dialog>
</template>
<script>
import videos from "./video";
import audios from "./audio";
import file from "./file";
export default {
    components: {
        videos,
        audios,
        file
    },
    data() {
        return {
            visible: false,
            info: {},
            playshow: false,
            endshow: false
        };
    },
    mounted() {
    },
    methods: {
        open(info) {
            this.info = info
            this.visible = true
        },
        handleClose() {
            this.visible = false
            this.playshow = false
            this.endshow = false
        },
        // 视频进度
        mediaPlayProgress(data) {
            let learn_time = Math.floor(data) / 60
            if (learn_time >= 6) {
                this.$refs.playVideo.pause()
                this.endshow = true
            }
        },
        play() {
            this.playshow = true
        },
        closes() {
            this.playshow = false
        },
        videopay() {
            this.$refs.playVideo.playagin()
            this.endshow = false
        }
    }
};
</script>
<style scoped>
.cur {
    cursor: pointer;
}

.gobuy {
    background: #409eff;
    color: #fff;
    padding: 8px 35px;
    border-radius: 4px;
    margin-top: 20px;
}

.flexHV-C {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.mt20 {
    margin-top: 20px;
}

.cf {
    color: #fff;
}

.flexV {
    display: flex;
    align-items: center;
}

.cover-bg {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background: rgba(0, 0, 0, 0.8);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
}

.question-item .el-col {
    padding-right: 5px;
}

.question-number {
    color: #0093ff;
}

.see-all {
    position: absolute;
    bottom: 45px;
    left: 10px;
    z-index: 9999;
    padding: 5px 15px;
    border-radius: 20px;
    color: #fff;
    background: rgba(0, 0, 0, 0.6);
}

.see-alls {
    position: absolute;
    top: 170px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9999;
    padding: 5px 15px;
    border-radius: 20px;
    color: #fff;
    background: rgba(0, 0, 0, 0.6);
}

.h500 {
    height: 500px;
}

.mr5 {
    margin-right: 5px;
}

.ml10 {
    margin-left: 10px;
}
</style>
