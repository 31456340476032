/* eslint-disable */
import config from "@/config"
import http from "@/utils/request"

export default {
    transaction: {
        list: {
            url: `${config.API_URL}/finance/company/transaction/list-page`,
            name: "交易列表",
            get: async function (params) {
                return await http.get(this.url, params);
            }
        },
        action: {
            url: `${config.API_URL}/finance/company/transaction`,
            name: "交易相关操作",
            get: async function (data) {
                return await http.get(this.url, data, {
                    headers: {}
                });
            },
            delete: async function (data) {
                return await http.delete(this.url, data, {
                    headers: {}
                });
            },
        },
        cancel: {
            url: `${config.API_URL}/finance/company/transaction/cancel`,
            name: '取消订单',
            action: async function (data) {
                return await http.put(this.url, data, {
                    headers: {}
                });
            },
        },
        pay: {
            url: `${config.API_URL}/finance/company/transaction/pay`,
            name: '支付订单',
            action: async function (data) {
                return await http.put(this.url, data, {
                    headers: {}
                });
            },
        },
        payment: {
            url: `${config.API_URL}/finance/company/transaction/payment-order`,
            name: '创建支付订单',
            action: async function (data) {
                return await http.post(this.url, data, {
                    headers: {}
                });
            },
        },
        paymentStatus: {
            url: `${config.API_URL}/finance/company/payment/pay-status`,
            name: "支付状态",
            get: async function (params) {
                return await http.get(this.url, params);
            }
        },
        companyConfig: {
            url: `${config.API_URL}/common/company/config/system`,
            name: '公司财务信息',
            get: async function (data) {
                return await http.get(this.url, data, {
                    headers: {}
                });
            }
        },
        companyConfigChange: {
            url: `${config.API_URL}/common/company/config`,
            name: '修改配置参数',
            put: async function (data) {
                return await http.put(this.url, data, {
                    headers: {}
                });
            },
        },
        companyConfigtwo: {
            url: `${config.API_URL}/common/company/config`,
            name: '开票信息',
            get: async function (data) {
                return await http.get(this.url, data, {
                    headers: {}
                });
            },
            put: async function (data) {
                return await http.put(this.url, data, {
                    headers: {}
                });
            }
        }
    },
    UserPort: {
        order: {
            list: {
                url: `${config.API_URL}/finance/company/transaction/list-page`,
                name: "交易列表",
                get: async function (params) {
                    return await http.get(this.url, params);
                }
            },
            action: {
                url: `${config.API_URL}/finance/company/transaction`,
                name: "交易相关操作",
                get: async function (data) {
                    return await http.get(this.url, data, {
                        headers: {}
                    });
                },
                delete: async function (data) {
                    return await http.delete(this.url, data, {
                        headers: {}
                    });
                },
            },
            cancel: {
                url: `${config.API_URL}/finance/company/transaction/cancel`,
                name: '取消订单',
                action: async function (data) {
                    return await http.put(this.url, data, {
                        headers: {}
                    });
                },
            },
            pay: {
                url: `${config.API_URL}/finance/company/transaction/pay`,
                name: '支付订单',
                action: async function (data) {
                    return await http.put(this.url, data, {
                        headers: {}
                    });
                },
            },
            payment: {
                url: `${config.API_URL}/finance/user/transaction/payment-order`,
                name: '创建支付订单',
                action: async function (data) {
                    return await http.post(this.url, data, {
                        headers: {}
                    });
                },
            },
            paymentStatus: {
                url: `${config.API_URL}/finance/user/payment/pay-status`,
                name: "支付状态",
                get: async function (params) {
                    return await http.get(this.url, params);
                }
            },
            offlinePayConfig: {
                url: `${config.API_URL}/common/user/config/system`,
                name: '公司财务信息',
                get: async function (data) {
                    return await http.get(this.url, data, {
                        headers: {}
                    });
                }
            },
        },
    }
}
/* eslint-enable */