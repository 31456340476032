/* eslint-disable */
import config from "@/config"
import http from "@/utils/request"

export default {
    list: {
        url: `${config.API_URL}/common/user/article/list-page`,
        name: "新闻列表",
        get: async function (params) {
            return await http.get(this.url, params);
        }
    },
    detail: {
        url: `${config.API_URL}/common/user/article`,
        name: "新闻详情",
        get: async function (params) {
            return await http.get(this.url, params);
        }
    }
}
/* eslint-enable */