/* eslint-disable */
import config from "@/config"
import http from "@/utils/request"

export default {
	company: {
		url: `${config.API_URL}/user-center/user/business/all`,
		name: "获取所有资源方",
		get: async function (params) {
			return await http.get(this.url, params);
		}
	},
	articleCategory: {
		url: `${config.API_URL}/common/user/article-category/list-tree`,
		name: "获取所帮助中心分类",
		get: async function (params) {
			return await http.get(this.url, params);
		}
	},
	article: {
		url: `${config.API_URL}/common/user/article/list-page`,
		name: "获取所帮助中心文章列表",
		get: async function (params) {
			return await http.get(this.url, params);
		}
	},
	articleDtail: {
		url: `${config.API_URL}/common/user/article`,
		name: "获取所帮助中心文章详情",
		get: async function (params) {
			return await http.get(this.url, params);
		}
	},
	collect: {
		url: `${config.API_URL}/common/user/collection`,
		name: "收藏、取消收藏",
		post: async function (data, config = {}) {
			return await http.post(this.url, data, config);
		},
		delete: async function (params) {
			return await http.delete(this.url, params);
		}
	},
	system: {
		url: `${config.API_URL}/common/user/config/system`,
		name: "获取系统配置",
		get: async function (params) {
			return await http.get(this.url, params);
		}
	},
	links: {
		url: `${config.API_URL}/common/user/links/list`,
		name: "获取友情链接",
		get: async function (params) {
			return await http.get(this.url, params);
		}
	},
	advert: {
		url: `${config.API_URL}/common/user/advert/list`,
		name: "获取广告列表",
		get: async function (data) {
			return await http.get(this.url, data);
		}
	},
	config: {
		system: {
			url: `${config.API_URL}/common/user/config/system`,
			name: "获取系统参数",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		}
	},
	video: {
		url: `${config.API_URL}/openApi/outLink/courseLink`,
		name: '视频播放',
		get: async function (data) {
			return await http.get(this.url, data)
		}
	}
}
/* eslint-enable */