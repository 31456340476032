<template>
    <div class="xiayiye">
        <div class="box prev" :class="{ disabledItem: page_list_number <= 1 || activePage == 1 }" @click="handleChange('prev')">上一页</div>
        <div class="box" :class="{ actived: item.value == activePage }" v-for="(item, index) in pages" :key="index" @click="toPage(item)">{{ item.value }}</div>
        <div class="box next" :class="{ disabledItem: page_list_number <= 1 || activePage == page_list_number }" @click="handleChange('next')">下一页</div>
        <span>前往</span>
        <input type="number" :min="1" oninput="if(value<0)value=1" @keydown.enter="enterSearch" v-model="jumpPage">
        <span>页</span>
    </div>
</template>

<script>
/* eslint-disable */
export default {
    emits: ['update:activePage', 'changePage'],
    props: {
        activePage: { type: Number, default: 1 },
        pageSize: { type: Number, default: 15 },
        total: { type: Number, default: 200 },
    },
    data() {
        return {
            jumpPage: ''
        }
    },
    computed: {
        page_list_number() {
            if (this.total <= this.pageSize) {
                return 1
            } else {
                return Math.ceil(this.total / this.pageSize)
            }
        },
        pages() {
            let totalPage = Math.ceil(this.total / this.pageSize)
            if (totalPage == 0) {
                totalPage = 1
            }
            // 组装分页页数数据
            let nowPages = []
            let addNumber = 0
            for (var i = 0; i < totalPage; i++) {
                if (addNumber < 5) {
                    if (this.activePage > 3 && ((i + 1) >= this.activePage - 2 && (i + 1) <= this.activePage + 2) && this.activePage + 2 <= totalPage) {
                        nowPages.push({ value: i + 1 })
                        addNumber++
                    } else if (this.activePage > 3 && this.activePage + 2 > totalPage && ((i + 1) >= this.activePage - 3 && (i + 1) <= this.activePage + 1)) {
                        nowPages.push({ value: i + 1 })
                        addNumber++
                    } else if (this.activePage > 3 && this.activePage + 1 > totalPage && ((i + 1) >= this.activePage - 4 && (i + 1) <= this.activePage)) {
                        nowPages.push({ value: i + 1 })
                        addNumber++
                    } else if (this.activePage <= 3) {
                        nowPages.push({ value: i + 1 })
                        addNumber++
                    }
                }
            }
            if (totalPage >= 7) {
                if ((totalPage >= nowPages[nowPages.length - 1].value + 2)) {
                    nowPages.push({ value: '...', mode: 'next' })
                    nowPages.push({ value: totalPage })
                }
                if (this.activePage >= 4) {
                    nowPages.unshift({ value: '...', mode: 'prev' })
                    nowPages.unshift({ value: 1 })
                }
            }
            return nowPages
        },
    },
    methods: {
        // 跳转到第几页
        enterSearch() {
            if (this.jumpPage == this.activePage) return
            let index = this.pages.findIndex(item => { return item.value == this.jumpPage })
            if (index != -1) {
                this.$emit('update:activePage', this.pages[index].value)
                this.$emit('changePage', this.pages[index].value)
            } else {
                this.jumpPage = ''
            }
        },
        // 前往第几页
        toPage(item) {
            if (item.value == this.activePage) return
            let number = null
            let totalPage = Math.ceil(this.total / this.pageSize)
            if (item.value == '...') {
                if (item.mode == 'next') {
                    if (this.activePage + 5 > totalPage) {
                        number = totalPage
                    } else {
                        number = this.activePage + 5
                    }
                } else {
                    if (this.activePage <= 5) {
                        number = 1
                    } else {
                        number = this.activePage - 5
                    }
                }
            } else {
                number = item.value
            }
            this.$emit('update:activePage', number)
            this.$emit('changePage', number)
        },
        // 改变页数
        handleChange(type) {
            let number = 0
            if (type == 'next') {
                if (this.activePage == this.page_list_number) {
                    return
                }
                number = this.activePage + 1
            } else {
                if (this.activePage == 1) {
                    return
                }
                number = this.activePage - 1
            }
            this.$emit('update:activePage', number)
            this.$emit('changePage', number)
        }
    }
}
/* eslint-enable */
</script>
<style lang="scss" scoped>
.xiayiye {
    @include flex(row, center, center);
    margin: 18px 0 0;

    span {
        margin: 0 5px;
    }

    .box {
        width: 40px;
        height: 38px;
        line-height: 38px;
        border: 1px solid #eeeeee;
        font-size: 14px;
        color: #666666;
        cursor: pointer;
        margin: 0 5px 0;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .actived,
    .box:hover {
        background: $theme1;
        color: #ffffff;
        border: 1px solid #ffffff;
    }

    input {
        height: 38px;
        width: 38px;
        border: 1px solid #eeeeee;
        font-size: 14px;
        margin: 0 5px 0;
        text-align: center;
    }

    .box.prev {
        width: 76px;
        height: 36px;
    }

    .box.next {
        width: 76px;
        height: 36px;
    }

    .box.actived,
    .xiayiye .item:hover {
        background-color: $theme1;
        color: #fff;
    }

    .box.disabledItem {
        color: #999;
    }

    .box.disabledItem:hover {
        background-color: #fff;
        color: #999;
        cursor: not-allowed;
        border: 1px solid #eeeeee;
    }
}
</style>