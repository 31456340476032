<template>
    <div class="jdjz pr hp100 wp100"  id="play-video-box">
        <video
            lang="zh"
            :controlslist="download ? '' : 'nodownload'"
            :oncontextmenu="download ? 'return true' : 'return false'"
            ref="videoPlayer"
            class="video-js vjs-default-skin vjs-big-play-centered hp100 wp100">
        </video>
    </div>
</template>
<script>
import videojs from "video.js";
import 'video.js/dist/video-js.css'
import { mapState } from 'vuex';
export default {
    components: {},
    data() {
        return {
            player: null,
            timer: null,
            lastSavetime: 0,
            paryTime: 0
        };
    },
    props: {
        src: {
            type: String,
            default: () => {
                return '';
            }
        },
        start: {
            type: Number,
            default: () => {
                return 0;
            }
        },
        icon: {
            type: Number,
            default: () => {
                return 0;
            }
        },
        status: {
            type: Number,
            default: () => {
                return 0;
            }
        },
        download: {
            type: Boolean,
            default: () => {
                return true;
            }
        }
    },
    computed: {
        ...mapState({
            systemConfig: state => state.global.systemConfig
        })
    },
    watch: {
        src: {
            // 深度监听
            deep: true,
            handler() {
                this.initVideo();
            }
        }
    },
    beforeUnmount() {
        if (this.player) {
            this.player.dispose();
        }
    },
    mounted() {
        videojs.addLanguage("zh-CN", {
            Play: "播放",
            Pause: "暂停",
            "Current Time": "当前时间",
            Duration: "时长",
            "Remaining Time": "剩余时间",
            "Stream Type": "媒体流类型",
            LIVE: "直播",
            Loaded: "加载完成",
            Progress: "进度",
            Fullscreen: "全屏",
            "Non-Fullscreen": "退出全屏",
            Mute: "静音",
            Unmute: "取消静音",
            "Playback Rate": "播放速度",
            Subtitles: "字幕",
            "subtitles off": "关闭字幕",
            Captions: "内嵌字幕",
            "captions off": "关闭内嵌字幕",
            Chapters: "节目段落",
            "Close Modal Dialog": "关闭弹窗",
            Descriptions: "描述",
            "descriptions off": "关闭描述",
            "Audio Track": "音轨",
            "You aborted the media playback": "视频播放被终止",
            "A network error caused the media download to fail part-way.":
                "网络错误导致视频下载中途失败。",
            "The media could not be loaded, either because the server or network failed or because the format is not supported.":
                "服务器或网络的问题无法加载。",
            "The media playback was aborted due to a corruption problem or because the media used features your browser did not support.":
                "由于视频文件损坏或是该视频使用了你的浏览器不支持的功能，播放终止。",
            "No compatible source was found for this media.":
                "无法找到此视频兼容的源。",
            "The media is encrypted and we do not have the keys to decrypt it.":
                "视频已加密，无法解密。",
            "Play Video": "播放视频",
            Close: "关闭",
            "Modal Window": "弹窗",
            "This is a modal window": "这是一个弹窗",
            "This modal can be closed by pressing the Escape key or activating the close button.":
                "可以按ESC按键或启用关闭按钮来关闭此弹窗。",
            ", opens captions settings dialog": ", 开启标题设置弹窗",
            ", opens subtitles settings dialog": ", 开启字幕设置弹窗",
            ", opens descriptions settings dialog": ", 开启描述设置弹窗",
            ", selected": ", 选择",
            "captions settings": "字幕设定",
            "Audio Player": "音频播放器",
            "Video Player": "视频播放器",
            Replay: "重新播放",
            "Progress Bar": "进度条",
            "Volume Level": "音量",
            "subtitles settings": "字幕设定",
            "descriptions settings": "描述设定",
            Text: "文字",
            White: "白",
            Black: "黑",
            Red: "红",
            Green: "绿",
            Blue: "蓝",
            Yellow: "黄",
            Magenta: "紫红",
            Cyan: "青",
            Background: "背景",
            Window: "窗口",
            Transparent: "透明",
            "Semi-Transparent": "半透明",
            Opaque: "不透明",
            "Font Size": "字体尺寸",
            "Text Edge Style": "字体边缘样式",
            None: "无",
            Raised: "浮雕",
            Depressed: "压低",
            Uniform: "均匀",
            Dropshadow: "下阴影",
            "Font Family": "字体库",
            "Proportional Sans-Serif": "比例无细体",
            "Monospace Sans-Serif": "单间隔无细体",
            "Proportional Serif": "比例细体",
            "Monospace Serif": "单间隔细体",
            Casual: "舒适",
            Script: "手写体",
            "Small Caps": "小型大写字体",
            Reset: "重置",
            "restore all settings to the default values": "恢复全部设定至预设值",
            Done: "完成",
            "Caption Settings Dialog": "字幕设定窗口",
            "Beginning of dialog window. Escape will cancel and close the window.":
                "打开对话窗口。Escape键将取消并关闭对话窗口",
            "End of dialog window.": "结束对话窗口",
            "Seek to live, currently behind live": "尝试直播，当前为延时播放",
            "Seek to live, currently playing live": "尝试直播，当前为实时播放",
            "progress bar timing: currentTime={1} duration={2}": "{1}/{2}",
            "{1} is loading.": "正在加载 {1}。"
        });
        this.$nextTick(function () {
            this.initVideo();
        });
    },
    methods: {
        getCssVideo() {
        },
        initVideo() {
            let that = this;
            let options = {
                controls: true,
                preload: "none",
                poster: this.systemConfig.system_pc_course_default_picture,
                language: "zh-CN",
                notSupportedMessage: this.src ? "未获取到视频源" : '视频转码中，清稍等'
            };

            that.lastSavetime = 0;
            that.paryTime = 0;
            if (this.player) {
                this.player.dispose();
            }
            let videoDiv = document.getElementById("play-video-box");
            let html =
                '<video id="play-video"  controlslist="' +
                (this.download ? "" : "nodownload") +
                '" oncontextmenu="' +
                (this.download ? "return true" : "return false") +
                '" class="video-js vjs-default-skin vjs-big-play-centered hp100 wp100">';
            videoDiv.innerHTML = html;
            let videoObj = document.getElementById("play-video");
            let src = []
            if (this.src instanceof Array) {
                this.src.forEach(ele => {
                    src.push({
                        src: ele
                    })
                })
            } else {
                src = [{ src: this.src }]
            }
            options.sources = src
            this.player = videojs(videoObj, options);
            if (!this.src.includes('.m3u8')) {
                this.player.src(this.src);
                this.player.load(this.src);
            }
            this.player.ready(function() {
                // 暂停事件监听
                that.paryTime = 0;
                this.on("loadstart", () => {
                    that.paryTime = 0;
                    let _start = parseInt(that.start);
                    if (isNaN(_start)) {
                        _start = 0;
                    }
                    that.paryTime = _start * 60;
                    if (that.status === 2) {
                        // 已学习完成的时候，用超大数字控制视频拖动范围
                        that.paryTime = 10000000000 * 60;
                        // 学习完成后，初始状态从零开始
                        videoObj.currentTime = 0;
                    } else {
                        // 学习中的视频，将视频重置到上次保存的播放时间的位置
                        videoObj.currentTime = that.paryTime;
                    }
                });

                this.on("timeupdate", () => {
                    if (videoObj.currentTime - that.paryTime > 1) {
                        // 防止拖动
                        videoObj.currentTime = that.paryTime;
                    } else if (videoObj.currentTime >= that.paryTime) {
                        // 每播放60秒保存一次数据库
                        that.lastSavetime = that.paryTime;
                        that.$emit("progress", that.paryTime);
                        that.paryTime = videoObj.currentTime;
                    }
                });
                this.on("play", (q) => {
                    let times = Math.floor(videoObj.currentTime) / 60
                    if (times >= 6) {
                        that.pause()
                    } else {
                        that.$emit('play')
                    }
                })
                this.on("pause", (q) => {
                    that.$emit('pause')
                })
                // 播放完成后执行一次保存数据库
                this.on("ended", (q) => {
                    that.lastSavetime = videoObj.currentTime;
                    that.$emit("progress", videoObj.currentTime);
                });
            });
            let vjscontrol = document.getElementsByClassName("vjs-control-bar")[0];
            vjscontrol.style.cssText = "z-index:100";
        },
        pause() {
            this.player.pause();
        },
        play() {
            this.player.play();
        },
        playagin() {
            setTimeout(() => {
                this.player.currentTime(0)
                this.player.play();
            }, 10);
        }
    }
};
</script>
<style scoped>
</style>

<style>
.vjs-poster {
    background-size: cover !important;
}

.hp100 {
    height: 100%;
}

.wp100 {
    width: 100%;
}
</style>

