/* eslint-disable */
import config from "@/config";

export default {
	state: {
		//移动端布局
		ismobile: false,
		//布局
		layout: config.LAYOUT,
		//菜单是否折叠 toggle
		menuIsCollapse: config.MENU_IS_COLLAPSE,
		//多标签栏
		layoutTags: config.LAYOUT_TAGS,
		//主题
		theme: config.THEME,
		// 定时器
		closeInt: false,
		// 购买后展示数据
		showcar: false,
		// 课程缓存参数
		coursemsg: '',
		//系统参数配置
		systemConfig: {},
	},
	mutations: {
		SET_ismobile(state, key) {
			state.ismobile = key
		},
		SET_layout(state, key) {
			state.layout = key
		},
		SET_theme(state, key) {
			state.theme = key
		},
		TOGGLE_menuIsCollapse(state) {
			state.menuIsCollapse = !state.menuIsCollapse
		},
		TOGGLE_layoutTags(state) {
			state.layoutTags = !state.layoutTags
		},
		SET_coursemsg(state, key) {
			state.coursemsg = key
		},
		SET_close(state, key) {
			state.closeInt = key
		},
		SET_car(state, key) {
			state.showcar = key
		},
		SET_systemConfig(state, key) {
			state.systemConfig = key
		},
	}
}
/* eslint-enable */