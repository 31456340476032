import config from "@/config"
import http from "@/utils/request"

export default {
    // 获取维护通知
    maintain: {
        maintainMessage: {
            url: `${config.API_URL}/message/user/system-message`,
            name: "获取系统参数",
            get: async function (data) {
                return await http.get(this.url, data);
            }
        }
    }
}
