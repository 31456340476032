/* eslint-disable */
import config from "@/config"
import http from "@/utils/request"

export default {
	activity: {
		url: `${config.API_URL}/activity/user/Activity/list-page`,
		name: "获取线下活动列表",
		get: async function (params) {
			return await http.get(this.url, params);
		}
	},
	detail: {
		url: `${config.API_URL}/activity/user/Activity`,
		name: "活动详情",
		get: async function (params) {
			return await http.get(this.url, params);
		}
	},
	apply: {
		url: `${config.API_URL}/activity/user/activityMember`,
		name: "申请活动",
		post: async function (params) {
			return await http.post(this.url, params);
		}
	},
}
/* eslint-enable */