/* eslint-disable */
import config from "@/config"
import http from "@/utils/request"

export default {
	phonecode: {
		url: `${config.API_URL}/user-center/user/auth/sms-code`,
		name: "员工手机验证码登录",
		get: async function (params) {
			return await http.get(this.url, params);
		}
	},
	companycode: {
		url: `${config.API_URL}/user-center/company/auth/sms-code`,
		name: "企业手机验证码登录",
		get: async function (params) {
			return await http.get(this.url, params);
		}
	},
	imgcode: {
		url: `${config.API_URL}/user-center/company/captcha`,
		name: "获取图形验证",
		get: async function (params) {
			return await http.get(this.url, params);
		}
	},
	imgcodeverify: {
		url: `${config.API_URL}/user-center/company/captcha/verify`,
		name: "验证图形验证码",
		post: async function (params) {
			return await http.post(this.url, params);
		}
	},
	// 员工
	token: {
		url: `${config.API_URL}/user-center/user/auth`,
		name: "登录获取TOKEN_PC",
		get: async function (params, config = {}) {
			return await http.get(this.url, params, config);
		}
	},
	user: {
		url: `${config.API_URL}/user-center/user/auth/user`,
		name: "获取当前登录人的信息",
		get: async function (params = {}) {
			return await http.get(this.url, params);
		}
	},
	// 企业
	tokenCompany: {
		url: `${config.API_URL}/user-center/company/auth`,
		name: "登录获取TOKEN_PC",
		get: async function (params, config = {}) {
			return await http.get(this.url, params, config);
		}
	},
	companyuser: {
		url: `${config.API_URL}/user-center/company/auth/company`,
		name: "获取当前企业的信息",
		get: async function (params = {}) {
			return await http.get(this.url, params);
		}
	},
	userCompany: {
		url: `${config.API_URL}/user-center/company/auth/user`,
		name: "获取当前登录人的信息",
		get: async function (params = {}) {
			return await http.get(this.url, params);
		}
	},
	pass: {
		url: `${config.API_URL}/user-center/employee/auth/password`,
		name: "修改密码",
		put: async function (params = {}) {
			return await http.put(this.url, params);
		}
	},
	company: {
		url: `${config.API_URL}/user-center/company/register`,
		name: "注册企业",
		post: async function (data) {
			return await http.post(this.url, data);
		},
	},
	userRegin: {
		url: `${config.API_URL}/user-center/user/register`,
		name: "注册学员",
		post: async function (data) {
			return await http.post(this.url, data);
		},
	},
	sendcode: {
		url: `${config.API_URL}/message/company/sms/verify-code`,
		name: "发送短信",
		post: async function (data) {
			return await http.post(this.url, data);
		},
	},
	sendcodeUser: {
		url: `${config.API_URL}/message/user/sms/verify-code`,
		name: "发送短信",
		post: async function (data) {
			return await http.post(this.url, data);
		},
	},
	newpasswordUser: {
		url: `${config.API_URL}/user-center/user/auth/password-by-sms-code`,
		name: "学员重置密码",
		put: async function (params = {}) {
			return await http.put(this.url, params);
		}
	},
	newpasswordCompany: {
		url: `${config.API_URL}/user-center/company/auth/password-by-sms-code`,
		name: "企业重置密码",
		put: async function (params = {}) {
			return await http.put(this.url, params);
		}
	},
	wechatLogin: {
		url: `${config.API_URL}/user-center/user/auth/pc-wechat-code`,
		name: "微信code登录",
		get: async function (data) {
			return await http.get(this.url, data);
		}
	},
	bindWechat: {
		url: `${config.API_URL}/user-center/user/auth/bind-wechat`,
		name: "绑定微信",
		put: async function (params = {}) {
			return await http.put(this.url, params);
		}
	}
}
/* eslint-enable */