import tool from '@/utils/tool';
import axios from 'axios';
import config from "@/config"


export function checkLogin() {
    if(tool.data.get("identity") === 'company') {
        let token = tool.data.get("TOKEN_COMPANY");
        let expiresTime = tool.data.get('expiresCompanyTime');
        let newTime = Math.round(new Date() / 1000);
        let isRefresh = tool.data.get("isRefreshCompany")
        if(isRefresh && expiresTime <= newTime) {
            tool.data.set("isRefreshCompany", false)
            axios({
                method: 'put',
                url: `${config.API_URL}/user-center/company/auth/refresh`,
                showErrorMessage: false,
                headers: { 'Authorization': 'Bearer ' + token }
            }).then(response => {
                tool.data.set("TOKEN_COMPANY", response.data.data.accessToken)
                store.commit("SET_TOKEN_COMPANY", response.data.data.accessToken);
                let time =  response.data.data.expiresIn + Math.round(new Date() / 1000)
                tool.data.set("expiresCompanyTime", time)
            });
        }
    } else {
        let userToken = tool.data.get("TOKEN");
        let expiresUserTime = tool.data.get('expiresUserTime');
        let userNewTime = Math.round(new Date() / 1000);
        let isRefreshUser = tool.data.get("isRefreshUser")
        if(isRefreshUser && expiresUserTime <= userNewTime) {
            tool.data.set("isRefreshUser", false)
            axios({
                method: 'put',
                url: `${config.API_URL}/user-center/user/auth/refresh`,
                showErrorMessage: false,
                headers: { 'Authorization': 'Bearer ' + userToken }
            }).then(response => {
                tool.data.set("TOKEN", response.data.data.accessToken)
                store.commit("SET_TOKEN", response.data.data.accessToken);
                let time =  response.data.data.expiresIn + Math.round(new Date() / 1000)
                tool.data.set("expiresUserTime", time)
            }).catch(_ => {
                localStorage.clear()
                window.location.href = "/"
            });
        }
    }
}


