<template>
    <div class="file-box"  v-loading="loading">
        <template v-if="!url || url.length === 0">
            <div class="corf5">文件格式转换中...！</div><br />
        </template>
        <iframe v-else :src="url" height="100%" width="100%" frameborder=”no” border=”0″></iframe>
    </div>
</template>
<script>
// import pdf from 'vue-pdf'
export default {
    data() {
        return {
        }
    },
    props: {
        url: {
            type: String,
            default: () => {
                return ''
            }
        }
    },
    watch: {
        url: {
            handler() {
            },
            deep: true,
            immediate: true
        }
    }
};
</script>
<style lang="scss" scoped>
.file-box {
    width:100%;
    height: 100%;
    padding: 0 20px;
}

.corf5 {
    color:#F56C6C
}
</style>

