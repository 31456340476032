/* eslint-disable */
import axios from 'axios';
import {
	ElNotification,
	ElMessageBox
} from 'element-plus';
import sysConfig from "@/config";
import tool from '@/utils/tool';
import router from '@/router';
import store from '../store'
import { checkLogin } from '@/utils/tokenRefresh.js';
import Aegis from 'aegis-web-sdk';

let isshow = true
let messageInstance = 0;
axios.defaults.baseURL = ''
axios.defaults.timeout = sysConfig.TIMEOUT
let userInfo = ''
if(tool.data.get("identity") == 'company') {
	tool.data.set("isRefreshCompany", true)
	userInfo = tool.data.get('USER_COMPANY')
}else {
	tool.data.set("isRefreshUser", true)
	userInfo = tool.data.get('USER_INFO')
}

let monitor = null 
if (process.env.NODE_ENV == "production") {
    // 前端性能监控初始化
	monitor = new Aegis({
		id: 'XV2vxigy1Ol3LV9pyL', // 应用ID，即上报ID
		uin: userInfo?.id || '', // 用户唯一 ID（可选）
		reportApiSpeed: true, // 接口测速
		reportAssetSpeed: true, // 静态资源测速
		spa: true // spa 应用页面跳转的时候开启 pv 计算
	})
}

// HTTP request 拦截器
axios.interceptors.request.use(
	(config) => {
		let token = tool.data.get("identity") ? (tool.data.get("identity") == 'company' ? tool.data.get("TOKEN_COMPANY") : tool.data.get("TOKEN")) : '';
		if (token) {
			checkLogin()
			config.headers[sysConfig.TOKEN_PC_NAME] = sysConfig.TOKEN_PC_PREFIX + token
		}
		if (!sysConfig.REQUEST_CACHE && config.method == 'get') {
			config.params = config.params || {};
			config.params['_'] = new Date().getTime();
		}
		Object.assign(config.headers, sysConfig.HEADERS)
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

function removeAuth() {
	tool.data.remove("TOKEN_COMPANY")
	tool.data.remove("USER_INFO_COMPANY")
	tool.data.remove("USER_INFO")
	tool.data.remove("TOKEN")
	store.commit("SET_TOKEN", null)
	store.commit("SET_TOKEN_COMPANY", null)
	store.commit("SET_USER_INFO", null)
	store.commit("SET_USER_COMPANY", null)

}

// HTTP response 拦截器
axios.interceptors.response.use(
	(response) => {
		if (response.data.error !== 0) {
			if (typeof (response.config.showErrorMessage) != "undefined" && response.config.showErrorMessage === false) {
				return Promise.reject(response)
			}
			if (response.data.error == 404) {
				if(messageInstance == 0) {
					ElNotification.closeAll()
                    ElNotification.error({
						title: '请求错误',
						message: "Status:404，正在请求不存在的服务器记录！"
					});
				}
				messageInstance ++
				setTimeout(() => {
                    messageInstance = 0
				}, 500)

			} else if (response.data.error == 500) {
				ElNotification.closeAll()
				if(messageInstance == 0) {
                    ElNotification.error({
						message: response.data.message || "Status:500，服务器发生错误！"
					});
				}
				messageInstance ++
				setTimeout(() => {
                    messageInstance = 0
				}, 500)
			} else if (response.data.error == 502) {
				// 登录要验证码
			} else if (response.data.error == 401) {
				if (isshow) {
					isshow = true
					store.commit("SET_close", true)
					tool.data.remove("identity")
					tool.data.remove("loginType")
				}
				removeAuth()
				isshow = false
			} else if (response.data.error == 402) {
				if (isshow) {
					store.commit("SET_close", true)
					tool.data.remove("identity")
					tool.data.remove("loginType")
				}
				removeAuth()
				isshow = false
			} else if (response.data.error == 403) {
				if (isshow) {
					if(messageInstance == 0) {
                        ElMessageBox.confirm('用户已被禁用或无权限访问当前资源，若有疑问请联系管理员', '无权限访问', {
							type: 'error',
							closeOnClickModal: false,
							center: true,
							confirmButtonText: '重新登录'
						}).then(() => {
							isshow = true
							tool.data.remove('TOKEN')
							tool.data.remove('USER_INFO')
							window.location.href = "/login"
						}).catch(() => {
							isshow = true
							tool.data.remove('TOKEN')
							tool.data.remove('USER_INFO')
							window.location.href = "/login"
						})
					}
					messageInstance ++
					setTimeout(() => {
						messageInstance = 0
					}, 500)
					store.commit("SET_close", true)
					tool.data.remove("identity")
					tool.data.remove("loginType")
				}
				removeAuth()
				isshow = false
			} else if (response.data.error == 405) {
				// 未绑定微信
			} else {
				if(messageInstance == 0) {
					ElNotification.closeAll()
					ElNotification.error({
						title: '请求错误',
						message: response.data.message || `Status:${response.data.error}，未知错误！`
					});
				}
				messageInstance ++
				setTimeout(() => {
					messageInstance = 0
				}, 500)
			}
			if (process.env.NODE_ENV == "production") {
                if(response.data.error == 500 && !response.config.noMonitor) {
					monitor.report({
						msg: '请求错误日志',
						level: Aegis.logType.AJAX_ERROR,
						ext1: response.data.message,      // 报错信息
						ext2: response.data.error,        // 报错状态码
						ext3: response.request.aegisUrl,  // 报错接口地址
						trace: 'trace',
					});
				}
			}
			return Promise.reject(response)
		}
		return response;
	},
	(error) => {
		if (error.response) {
			if (error.response.status == 404) {
				if(messageInstance == 0) {
					ElNotification.closeAll()
                    ElNotification.error({
						title: '请求错误',
						message: "Status:404，正在请求不存在的服务器记录！"
					});
				}
				messageInstance ++
				setTimeout(() => {
					messageInstance = 0
				}, 500)
			} else if (error.response.status == 500) {
				ElNotification.closeAll()
				if(messageInstance == 0) {
					ElNotification.error({
						message: error.response.data.message || "Status:500，服务器发生错误！"
					});
				}
				messageInstance ++
				setTimeout(() => {
					messageInstance = 0
				}, 500)

			} else if (error.response.status == 502) {
				// 登录要验证码
			} else if (error.response.status == 401) {
				store.commit("SET_close", true)
				if(messageInstance == 0) {
                    ElMessageBox.confirm('当前用户已被登出或无权限访问当前资源，请尝试重新登录后再操作。', '无权限访问', {
						type: 'error',
						closeOnClickModal: false,
						center: true,
						confirmButtonText: '重新登录'
					}).then(() => {
						router.replace({
							path: '/login'
						});
					})
				}
				messageInstance ++
				setTimeout(() => {
					messageInstance = 0
				}, 500)

			} else {
				if(messageInstance == 0) {
					ElNotification.closeAll()
                    ElNotification.error({
						title: '请求错误',
						message: error.response.data.message || `Status:${error.response.status}，未知错误！`
					});
				}
				messageInstance ++
				setTimeout(() => {
					messageInstance = 0
				}, 500)
			}
		} else {
			if(messageInstance == 0) {
				ElNotification.closeAll()
                ElNotification.error({
					title: '请求错误',
					message: "请求服务器无响应！"
				});
			}
			messageInstance ++
			setTimeout(() => {
				messageInstance = 0
			}, 500)
		}
		if (process.env.NODE_ENV == "production" && error) {
            monitor.report({
				msg: '请求错误日志',
				level: Aegis.logType.AJAX_ERROR,
				ext1: error.response?.data,      // 报错信息
				ext2: error.response?.status,        // 报错状态码
				ext3: error.response?.config?.url,  // 报错接口地址
				trace: 'trace',
			});
		}
		return Promise.reject(error.response)
	}
);

var http = {

	/** get 请求
	 * @param  {接口地址} url
	 * @param  {请求参数} params
	 * @param  {参数} config
	 */
	get: function (url, params = {}, config = {}) {
		return new Promise((resolve, reject) => {
			axios({
				method: 'get',
				url: url,
				params: params,
				...config
			}).then((response) => {
				resolve(response.data);
			}).catch((error) => {
				reject(error);
			})
		})
	},

	/** post 请求
	 * @param  {接口地址} url
	 * @param  {请求参数} data
	 * @param  {参数} config
	 */
	post: function (url, data = {}, config = {}) {
		return new Promise((resolve, reject) => {
			axios({
				method: 'post',
				url: url,
				data: data,
				...config
			}).then((response) => {
				resolve(response.data);
			}).catch((error) => {
				reject(error);
			})
		})
	},

	/** put 请求
	 * @param  {接口地址} url
	 * @param  {请求参数} data
	 * @param  {参数} config
	 */
	put: function (url, data = {}, config = {}) {
		return new Promise((resolve, reject) => {
			axios({
				method: 'put',
				url: url,
				data: data,
				...config
			}).then((response) => {
				resolve(response.data);
			}).catch((error) => {
				reject(error);
			})
		})
	},

	/** patch 请求
	 * @param  {接口地址} url
	 * @param  {请求参数} data
	 * @param  {参数} config
	 */
	patch: function (url, data = {}, config = {}) {
		return new Promise((resolve, reject) => {
			axios({
				method: 'patch',
				url: url,
				data: data,
				...config
			}).then((response) => {
				resolve(response.data);
			}).catch((error) => {
				reject(error);
			})
		})
	},

	/** delete 请求
	 * @param  {接口地址} url
	 * @param  {请求参数} data
	 * @param  {参数} config
	 */
	delete: function (url, data = {}, config = {}) {
		return new Promise((resolve, reject) => {
			axios({
				method: 'delete',
				url: url,
				data: data,
				...config
			}).then((response) => {
				resolve(response.data);
			}).catch((error) => {
				reject(error);
			})
		})
	},

	/** jsonp 请求
	 * @param  {接口地址} url
	 * @param  {JSONP回调函数名称} name
	 */
	jsonp: function (url, name = 'jsonp') {
		return new Promise((resolve) => {
			var script = document.createElement('script')
			var _id = `jsonp${Math.ceil(Math.random() * 1000000)}`
			script.id = _id
			script.type = 'text/javascript'
			script.src = url
			window[name] = (response) => {
				resolve(response)
				document.getElementsByTagName('head')[0].removeChild(script)
				try {
					delete window[name];
				} catch (e) {
					window[name] = undefined;
				}
			}
			document.getElementsByTagName('head')[0].appendChild(script)
		})
	}
}

export default http;
/* eslint-enable */