<template>
    <div class="race-box">
        <div class="flex-R race-div cursor bag1" @click="goDetailFun()">
            <div class="racepic" :style="{ 'background-image': 'url(\'' + item.icon.url + '\')' }">
                <div class="wh1"></div>
                <div class="nunsj">{{ item.signedNumber }}/{{ item.limitNumber }}</div>
            </div>
            <div class="race-content  flex1">
                <div class="f18 title textEll2">{{ item.name }}</div>
                <p class=" mt25 lh22">{{ item.abstract }}</p>
                <div class="flex-sb">
                    <div class="f12 race-time">{{ item.startDate }} ~ {{ item.endDate }}</div>
                    <div class=" f16 cousre-item flexH-R" :class="item.status.value == 3 ? 'course-item-hui' : ''">
                        <div v-if='item.status.value == 1 && item.signed == 1 || item.status.value == 2 && item.signed == 1' class="choose-css btn2">已报名</div>
                        <div v-if='item.status.value == 3' class="choose-css btn3">已结束</div>
                        <div v-if='item.status.value == 2 && item.signed != 1' class="choose-css btn2">进行中</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: '',
    props: {
        arr: {
            type: Array
        },
        index: {
            type: Number
        },
        item: {}
    },
    computed: {
        ...mapState({
            vuex_userdata: state => state.user.vuex_userdata
        })
    },
    methods: {
        goOtherurl() {
            window.open(this.item.sign_up_url, '_blank')
        },
        goDetailFun() {
            this.$router.push({ path: '/activity/detail', query: { activityid: this.item.id } })
        },
        apply(item) {
            console.log(true, this.index)
            if (item.limit_number && (item.signed_number >= item.limit_number)) {
                this.$message({
                    message: '报名人数已满',
                    type: 'error'
                })
            } else {
                if (this.vuex_userdata.name) {
                    this.$emit('apply', true, this.index)
                } else {
                    this.$store.dispatch('user/setloginstatus', true)
                }
            }
        }
    }
}
</script>

<style scoped lang="scss">
.race-box {
    margin-right: 14px;
}

.race-box:nth-child(3n) {
    margin-right: 0;
}

.racepic {
    width: 100%;
    height: 213px;
    border-radius: 5px 5px 0 0;
    cursor: pointer;
    background-size: cover;
    padding: 0 10px;
    position: relative;
}

.nunsj {
    text-align: center;
    line-height: 25px;
    background: #666;
    color: #fff;
    margin-top: 180px;
    border-radius: 5px;
    position: absolute;
    right: 10px;
    padding: 0 10px;
}

.race-div {
    border: 1px solid #e9f0f5;
    ;
    margin-top: 35px;
    border-radius: 5px;
}

.choose-css {
    background: #fe8c00;
    color: #FFFFFF !important;
    padding: 6px 15px;
    border-radius: 20px 0 0px 20px;
    cursor: pointer;
    font-size: 12px;
}

.choose-css.btn3 {
    background: #bfbfbf;
}

.race-time {
    padding: 8px 15px 7px 15px;
    border-radius: 5px;
    background: #fff7e5;
    color: #ffaf00;
    margin-left: 5px;
}

.title {
    width: 95%;
    margin: auto;
    margin-top: 26px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #333333;
    opacity: 1;
}

.lh22 {
    width: 95%;
    height: 40px;
    margin: auto;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #666666;
    opacity: 1;
    margin-top: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;

}

.flex-R {
    width: 377px;
    height: 400px;
    margin-left: 11px;
    transition: all 0.5s ease-in-out;
    background: #fff;
}

.flex-R:hover {
    transform: translateY(-4px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.06);
    transition: 0.5s;
}

.flexH-R {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.flex-sb {
    display: flex;
    justify-content: space-between;
    margin-top: 28px;
}

.cousre-item {
    margin-right: -6px;
    position: relative;
}

.cousre-item::after {
    position: absolute;
    content: " ";
    display: block;
    width: 0;
    /*1.设置宽高为0*/
    height: 0;
    /*2.除右边都变透明色，实边*/
    border-color: transparent transparent transparent #955200;
    border-style: solid;
    border-width: 0 0 5px 6px;
    /*3.上边宽100，右边宽50，下左边宽0*/
    right: 0;
    bottom: -5px;
}

.course-item-hui::after {
    border-color: transparent transparent transparent #999999;
}

.wh1 {
    width: 1px;
    height: 1px;
}
</style>
