/* eslint-disable */
import config from "@/config"
import http from "@/utils/request"

export default {
    center: {
        screen: {
            url: `${config.API_URL}/course-center/user/business/course-category/system-list-tree`,
            name: "课程树形",
            get: async function (params) {
                return await http.get(this.url, params);
            }
        },
        courselist: {
            url: `${config.API_URL}/course-center/user/business/course/list-page`,
            name: "课程列表",
            get: async function (params) {
                return await http.get(this.url, params);
            }
        },
        concentrationList: {
            url: `${config.API_URL}/course-center/user/business/course/concentration-list`,
            name: "精选课程列表",
            get: async function (params) {
                return await http.get(this.url, params);
            }
        },
        coursedetail: {
            url: `${config.API_URL}/course-center/user/business/course`,
            name: "课程详情",
            get: async function (params) {
                return await http.get(this.url, params);
            }
        },
        freeTrialLink: {
            url: `${config.API_URL}/course-center/user/business/course/free-trial-link`,
            name: "试看链接",
            get: async function (params) {
                return await http.get(this.url, params);
            }
        },
        courseware: {
            url: `${config.API_URL}/course-center/user/business/course/attachment-list-page`,
            name: "课件列表",
            get: async function (params) {
                return await http.get(this.url, params);
            }
        },
        coursetimetable: {
            url: `${config.API_URL}/course-center/user/business/course/timetable-list`,
            name: "课程表列表",
            get: async function (params) {
                return await http.get(this.url, params);
            }
        },
        comment: {
            url: `${config.API_URL}/course-center/user/business/course/comment-list-page`,
            name: "评论列表",
            get: async function (params) {
                return await http.get(this.url, params);
            }
        },
        commentAction: {
            url: `${config.API_URL}/course-center/company/course-comment`,
            name: "评论发布和删除",
            post: async function (data) {
                return await http.post(this.url, data, {
                    headers: {}
                });
            },
            delete: async function (data) {
                return await http.delete(this.url, data, {
                    headers: {}
                });
            },
        },
        outLinks: {
            linkUrl: {
                url: `${config.API_URL}/course-center/user/out-link-course/out-link-url`,
                name: "快链课程地址",
                get: async function (params) {
                    return await http.get(this.url, params);
                }
            }
        },
        car: {
            addcar: {
                url: `${config.API_URL}/finance/user/shop-cart`,
                name: "添加购物车",
                post: async function (params) {
                    return await http.post(this.url, params);
                }
            },
            carlist: {
                url: `${config.API_URL}/finance/user/shop-cart/list`,
                name: "购物车列表",
                get: async function (params) {
                    return await http.get(this.url, params);
                }
            },
            cardelete: {
                url: `${config.API_URL}/finance/user/shop-cart`,
                name: "购物车删除",
                delete: async function (params) {
                    return await http.delete(this.url, params);
                }
            },
            order: {
                url: `${config.API_URL}/finance/user/transaction`,
                name: "创建订单",
                post: async function (params) {
                    return await http.post(this.url, params);
                }
            },
        }
    },
    car: {
        addcar: {
            url: `${config.API_URL}/finance/company/shop-cart`,
            name: "添加购物车",
            post: async function (params) {
                return await http.post(this.url, params);
            }
        },
        carlist: {
            url: `${config.API_URL}/finance/company/shop-cart/list`,
            name: "购物车列表",
            get: async function (params) {
                return await http.get(this.url, params);
            }
        },
        cardelete: {
            url: `${config.API_URL}/finance/company/shop-cart`,
            name: "购物车删除",
            delete: async function (params) {
                return await http.delete(this.url, params);
            }
        },
        order: {
            url: `${config.API_URL}/finance/company/transaction`,
            name: "创建订单",
            post: async function (params) {
                return await http.post(this.url, params);
            }
        },
    },
    catalogue: {
        list: {
            url: `${config.API_URL}/course-center/company/course-catalogue/list`,
            name: "分类列表",
            get: async function (params) {
                return await http.get(this.url, params);
            }
        },
        sort: {
            url: `${config.API_URL}/course-center/company/course-catalogue/sort`,
            name: "排序列表",
            put: async function (params) {
                return await http.put(this.url, params);
            }
        },
        action: {
            url: `${config.API_URL}/course-center/company/course-catalogue`,
            name: "新增或更新目录",
            post: async function (data) {
                return await http.post(this.url, data, {
                    headers: {}
                });
            },
            put: async function (data) {
                return await http.put(this.url, data, {
                    headers: {}
                });
            },
            delete: async function (data) {
                return await http.delete(this.url, data, {
                    headers: {}
                });
            },
        }
    },
    category: {
        list: {
            url: `${config.API_URL}/course-center/company/course-category/list-tree`,
            name: "分类列表",
            get: async function (params) {
                return await http.get(this.url, params);
            }
        },
        action: {
            url: `${config.API_URL}/course-center/company/course-category`,
            name: "新增或更新分类",
            post: async function (data) {
                return await http.post(this.url, data, {
                    headers: {}
                });
            },
            put: async function (data) {
                return await http.put(this.url, data, {
                    headers: {}
                });
            },
            delete: async function (data) {
                return await http.delete(this.url, data, {
                    headers: {}
                });
            },
        }
    },
    attachment: {
        list: {
            url: `${config.API_URL}/course-center/company/course-attachment/list-page`,
            name: "附件列表",
            get: async function (params) {
                return await http.get(this.url, params);
            }
        },
        action: {
            url: `${config.API_URL}/course-center/company/course-attachment`,
            name: "新增或更新",
            post: async function (data) {
                return await http.post(this.url, data, {
                    headers: {}
                });
            },
            put: async function (data) {
                return await http.put(this.url, data, {
                    headers: {}
                });
            },
            delete: async function (data) {
                return await http.delete(this.url, data, {
                    headers: {}
                });
            },
        }
    },
    comment: {
        list: {
            url: `${config.API_URL}/course-center/company/course-comment/list-page`,
            name: "评论列表",
            get: async function (params) {
                return await http.get(this.url, params);
            }
        },
    },
}
/* eslint-enable */