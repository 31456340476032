<template>
    <el-config-provider :locale="$i18n.messages[$i18n.locale].el">
        <router-view></router-view>
    </el-config-provider>
</template>

<script>
export default {
    name: 'App'
}
</script>

<style lang="scss">
@import '@/style/base.scss';
@import '@/style/mixin.scss';
@import '@/style/style.scss';
@import '@/style/fix.scss';
@import '@/style/pages.scss';
@import '~@/assets/font/uboldicon/icons.css';
@import '~@/assets/font/alicon/iconfont.css';
@import '~@/assets/font/lineicon/iconfont.css';

body {
    min-width: 500px;
}
</style>
