<template>
    <div class="tabsbox flexV">
        <div class="items" @click="tabFun(bb)" :class="{ actived: indexnum === bb }" :style="'color:' + textcolor" v-for="(item, bb) in arr" :key="bb">{{ objs ? item.name : item }}</div>
    </div>
</template>

<script>
export default {
    name: 'tabs',
    data() {
        return {
            indexnum: 0
        }
    },
    props: {
        arr: {
            type: Array
        },
        index: {
            type: Number
        },
        center: {
            type: Number
        },
        type: {
            type: String,
            default: ''
        },
        textcolor: {
            type: String
        },
        objs: Boolean,
        manual: Boolean
    },
    methods: {
        tabFun(key) {
            this.indexnum = key
            if (!this.manual) {
                this.$emit('change', key)
            }
        }
    }
}
</script>

<style scoped lang="scss">
.flexV {
    display: flex;
    align-items: center;
}

.f14 {
    font-size: 14px;
}

.tabsbox {
    height: 35px;
    line-height: 35px;
    border-bottom: 1px solid #ededed;
}

.tabsbox .items {
    font-size: 14px;
    padding: 0 22px;
    cursor: pointer;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.tabsbox .items.actived {
    font-size: 14px;
    position: relative;
    ;
    color: #fff !important;
    background: #fe8c00;
    border-radius: 10px 10px 0 0;
}

.tabsbox .items.actived:after {
    content: '';
    display: block;
    background: #fe8c00;
    border-radius: 50px;
    width: 50px;
    height: 2px;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
}

.text-center {
    justify-content: center;
}
</style>
