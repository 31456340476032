import { createApp } from 'vue'
import App from './App.vue'
import i18n from './locales'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/display.css'
import gl_default from './components/gl_default'
import gl_catalogue from './components/gl_catalogue'
import gl_tabs from './components/gl_tabs'
import gl_srace from './components/gl_srace'
import gl_raceapp from './components/gl_raceapp'
import router from './router'
import store from './store'
import api from './api'
import tool from './utils/tool'
import config from "./config"
import scTable from './components/scTable'
import scDialog from './components/scDialog'
import { Swiper, Pagination, Autoplay } from 'swiper'
import 'swiper/swiper-bundle.min.css'
import Glpapin from './components/gl_papin'
import Aegis from 'aegis-web-sdk';
import './icons'
Swiper.use([Pagination, Autoplay])

const app = createApp(App);

let monitor = null
if(process.env.NODE_ENV === "production") {
    // 前端性能监控初始化
    monitor = new Aegis({
        id: 'XV2vxigy1Ol3LV9pyL', // 应用ID，即上报ID
        reportApiSpeed: true, // 接口测速
        reportAssetSpeed: true, // 静态资源测速
        spa: true // spa 应用页面跳转的时候开启 pv 计算
    })
}

// 公共配置
app.use(store);
app.config.globalProperties.$SWIPER = Swiper;
app.config.globalProperties.$CONFIG = config;
app.config.globalProperties.$AEGIS = Aegis;
app.config.globalProperties.$AEGISING = monitor;
app.config.globalProperties.$TOOL = tool;
app.config.globalProperties.$API = api;
// 第三方使用
app.use(i18n);
app.use(router);
app.use(ElementPlus, { size: 'small' });
// 组件创建
app.component('gl_catalogue', gl_catalogue);
app.component('gl_default', gl_default);
app.component('gl_tabs', gl_tabs);
app.component('gl_srace', gl_srace);
app.component('gl_raceapp', gl_raceapp);
app.component('Glpapin', Glpapin);
app.component('scTable', scTable);
app.component('scDialog', scDialog);

// 挂载app
app.mount('#app');