<template>
    <div>
        <div v-for="(item, index) in arr" :key="index">
            <!-- 任务 -->
            <div class="course_list" @click="item.tryPreview && item.tryPreview.val == 1 ? viewHander(item) : ''" v-if="item.type.val == 3">
                <div class="course_cell pl0">
                    <img class="icon" src="@/assets/png/z3.png" alt="" />
                    <div class="course_cell_content">
                        <div class="flexV">
                            <div class="course_cell-title">{{ item.name }}</div>
                            <el-tag size="mini" class="cur try_see" v-if="item.tryPreview && item.tryPreview.val == 1">试看</el-tag>
                        </div>
                        <div class="course_cell-time flexV">
                            <div class="time_pr">{{ item.fileType.name }}</div>
                            <div class="" v-if="!type">
                                时长：{{ item.time }}分钟<span v-if="item.time > 0">已学时长：{{ item.time }}分钟</span>
                            </div>
                            <div class="" v-else>
                                时长：{{ item.time }}分钟
                            </div>
                        </div>
                    </div>
                </div>
                <img src="@/assets/png/img21.png" class="userimg user-suo" alt="">
            </div>
            <div class="taskitems flexV" v-if="item.type.val == 2">
                <img src="@/assets/png/z1.png" alt="" />
                <span class="textEll">第{{ item.index + 1 }}节:{{ item.name }}</span>
            </div>
            <el-collapse class="task_child_item" :key="item.id" v-else-if="item.type.val == 1" v-model="activeNames"
                @change="changes">
                <el-collapse-item :title="'第' + (index + 1) + '章: ' + item.name" :name="index">
                    <template #title>
                        <img class="img-z1"  src="@/assets/png/z1.png" alt="" />
                        <span class="textEll chapter-line">第{{ index + 1 }}章:{{ item.name }}</span>
                    </template>
                    <template v-for="(item2, index2) in item.taks" :key="index2">
                        <div class="course_list" v-if="item.type.val == 1" @click="item2.tryPreview && item2.tryPreview.val == 1 && item2.status.val == 1 ? viewHander(item2) : ''">
                            <div class="course_cell">
                                <img class="icon" src="@/assets/png/z3.png" alt="" />
                                <div class="course_cell_content">
                                    <div class="flexV">
                                        <div class="course_cell-title">{{ item2.name }}</div>
                                        <el-tag size="mini" class="cur tag-s"  v-if="item2.tryPreview && item2.tryPreview.val == 1 && item2.status.val == 1">试看</el-tag>
                                    </div>
                                    <div class="course_cell-time flexV">
                                        <div class="time_pr">{{ item2.fileType.name }}</div>
                                        <div class="" v-if="!type">
                                            时长：{{ item2.time }}分钟<span v-if="item2.time > 0">已学时长：{{ item2.time }}分钟</span>
                                        </div>
                                        <div class="" v-else>
                                            时长：{{ item2.time }}分钟
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <img src="@/assets/png/img21.png" class="userimg user-suo" alt="" />
                        </div>
                        <div class="taskitems flexV" v-if="item.type.val == 2">
                            <img  src="@/assets/png/z1.png" alt="" />
                            <span class="textEll">第{{ item.index + 1 }}节:{{ item.name }}</span>
                        </div>
                    </template>
                </el-collapse-item>
            </el-collapse>
        </div>
        <preview ref="preview" />
    </div>
</template>

<script>
import preview from "../preview";
export default {
    /* eslint-disable */
    props: {
        courseLists: {
            type: Array
        },
        type: {
            type: String
        },
        courseId: {
            type: Number,
            default: 0
        }
    },
    components: {
        preview
    },
    data() {
        return {
            activeNames: [],
            list: [
                { name: '安全教育', score: 55, pass: 80, total: 100, useTime: "01:21", num: 0, startTime: "2021-11-22 12:30", status: "已通过" },
                { name: '安全教育', score: 55, pass: 80, total: 100, useTime: "01:21", num: 0, startTime: "2021-11-22 12:30", status: "已通过" }
            ],
            noclick: 1,
            arr: [],
            identity: ''
        }
    },
    created() {
        this.identity = this.$TOOL.data.get('identity')
    },
    watch: {
        courseLists(val) {
            this.arr = this.gettasklist(val)
            this.arr.map((item, index) => {
                this.activeNames.push(index)
            })
        }
    },
    computed: {
        id: {
            get() {
                return this.courseId
            },
            set(val) {
                this.$emit('update:courseId', val)
            }
        }
    },
    mounted() {

    },
    methods: {
        viewHander(row) {
            //判断当前试看课程是否是资源方的
            if (row.fileType.val === 5 || row.fileType.val === 6) {
                this.$API.course.center.freeTrialLink.get({id: row.id})
                .then(res=>{
                    row.previewFile = []
                    row.previewFile.push(res.data.playMediaUrl)
                    this.$nextTick(() => {
                        this.$refs.preview.open(row)
                    })
                })
            } else {
                this.$nextTick(() => {
                    this.$refs.preview.open(row)
                })
            }
        },
        gettasklist(data) {
            let _taks = []
            let _isChapter = false
            let _taskSub = []
            data.forEach(ele => {
                ele.openload = false
                if (ele.type.val === 1) {
                    _isChapter = true;
                    if (_taskSub && _taks[_taks.length - 1]) {
                        _taks[_taks.length - 1].taks = _taskSub
                    }
                    _taskSub = []
                    ele.taks = []
                    _taks.push(ele)
                } else if (!_isChapter) {
                    _taks.push(ele)
                } else {
                    _taskSub.push(ele)
                }
            })

            if (_taskSub.length) {
                _taks[_taks.length - 1].taks = _taskSub
            }
            return _taks
        },
    },
}
/* eslint-enable */
</script>

<style lang="scss" scoped>

.flexV {
    display: flex;
    align-items: center;
}


.course {
    display: flex;
}

.course:hover {
    cursor: pointer;
}

.course h3 {
    margin-bottom: 10px;
    font-weight: 400;
}

.course .desc {
    flex: 1;
    min-width: 0;
}

.course .imgbox {
    width: 150px;
    background: olivedrab;
    margin-right: 20px;
}

.teacher {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.teacher .userimg {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background: #bfbfbf;
    margin-right: 5px;
}

.left,
.right {
    display: flex;
    flex: 3;
}

.right {
    flex: 2;
    align-items: center;
    justify-content: flex-end;
}

.boxs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    text-align: center;
}

.boxs:after {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 0;
    height: 100%;
    width: 1px;
    background: #e6e6e6;
}

.boxs>div {
    width: 100px;
}

.el-collapse {
    border-top: unset;
}

::v-deep(.el-collapse-item__header) {
    border-bottom: 1px solid #eeeeee;
    font-weight: bold;
}

::v-deep(.el-collapse-item__header.is-active) {
    border-bottom-color: #eeeeee;
}

.course_list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.detail-content div img.userimg{
    width: 16px;
    height: 16px;
    margin-right: 6.5px;
}

.user-suo {
    width: 16px;
    height: 16px;
    margin-right: 6.5px;
}

::v-deep(.el-collapse-item__content) {
    padding: 0;
}

.course_cell {
    display: flex;
    padding-left: 15px;
}

.course_list {
    padding: 10px 0;
    border-bottom: 1px solid #eeeeee;
}

::v-deep(.el-collapse-item__wrap) {
    border-bottom: unset;
}

.course_cell_content>div {
    line-height: 12px;
    font-size: 12px;
}

.course_cell-title {
    color: #666;
    height: 20px;
    line-height: 20px;
}

.course_cell-time {
    color: #999;
    margin-top: 10px;
}

.exam_table {
    font-size: 12px;
    color: #606266;
}

.exam_table_list {
    display: flex;
}

.exam_table_cell {
    display: flex;
    padding: 8px 0;
    border-bottom: 1px solid #ebeef5;
    align-items: center;
}

.exam_table_cell div {
    flex: 1;
    padding: 0 10px;
}

.exam_table_list div {
    color: #909399;
    font-weight: bold;
}

.course_cell-time span {
    margin-left: 20px;
}

.cur {
    cursor: pointer;
}

.item_animate {
    overflow: hidden;
    transition: all 0.3s;
    height: auto;
}

.textEll {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: normal;
}

.time_pr {
    position: relative;
    padding-right: 7.5px;
    margin-right: 7.5px;
}

.time_pr::after {
    content: " ";
    display: block;
    position: absolute;
    background: #b3b3b3;
    width: 1px;
    height: 12px;
    right: 0px;
    top: 0px;
}

.try_see {
    background:#fff;
    margin-left: 5px;
}

.taskitems img {
    width:14px;
    margin-right: 5px;
}

.taskitems span {
    padding-right: 20px;
    color: #333;
    font-size:14px;
}

.img-z1 {
    width:14px;
    margin-right: 5px;
}

.chapter-line {
    padding-right: 20px;
    color: #333;
    font-size:14px;
}

.tag-s {
    background:#fff;
    margin-left: 5px;
}

.pl0{
    padding-left: 0px;
}   


.icon {
    width: 14px;
    height: 14px;
    margin-right: 5px;
    margin-top: 2px;
}
</style>