/* eslint-disable */
import config from "@/config"
import http from "@/utils/request"

export default {
    feedback:{
        companyFeedback: {
            url: `${config.API_URL}/message/company/feedback`,
            name: "企业提交反馈意见",
            post: async function (params) {
                return await http.post(this.url, params);
            },
        },
        companyCategory: {
            url: `${config.API_URL}/message/company/feedback/all-category`,
            name: "获取企业意见反馈分类列表",
            get: async function (params) {
                return await http.get(this.url, params);
            }
        },
        userCategory: {
            url: `${config.API_URL}/message/user/feedback/all-category`,
            name: "获取用户意见反馈分类列表",
            get: async function (params) {
                return await http.get(this.url, params);
            }
        },
        userFeedback: {
            url: `${config.API_URL}/message/user/feedback`,
            name: "用户提交反馈意见",
            post: async function (params) {
                return await http.post(this.url, params);
            }
        }
    }
}
/* eslint-enable */