/* eslint-disable */
export default {
	state: {
		search_name: ''
	},
	mutations: {
		SET_NAME(state, key) {
			state.search_name = key
		}
	},
	action: {
		setName({ commit }, key) {
			commit('SET_NAME', key)
		}
	}
}
/* eslint-enable */