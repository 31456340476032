<template>
    <div class="login-div">
        <div class='bag1 content-div'>
            <div class='flexV-sb content-title'>
                <div class='18'>活动报名</div>
                <img class="cursor closebtn" @click='closebtn' src='@/assets/png/pic53.png' />
            </div>
            <div class='pl40 pr40 form-content'>
                <el-form :model="reqadata" :rules="ruleForm" ref="ruleForm" size="small" label-width="80px">
                    <el-row :gutter="24">
                        <el-col :span="12">
                            <el-form-item label="活动">
                                <el-input v-model="reqadata.acname" type="text" disabled />
                            </el-form-item>
                            <el-form-item label="姓名" prop="name">
                                <el-input v-model="reqadata.name" type="text" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="证件号" prop="credential_code">
                                <el-input v-model="reqadata.credential_code" type="number" />
                            </el-form-item>

                            <el-form-item label="证件类型" prop="cardvalue">
                                <el-select class='flex1' v-model="reqadata.cardvalue" placeholder="请选择证件类型">
                                    <el-option v-for="item in cardoptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="联系方式" prop="mobile">
                                <el-input v-model="reqadata.mobile" maxlength="13" type="number" />
                            </el-form-item>
                            <el-form-item label="学校" prop="schoole">
                                <el-input v-model="reqadata.schoole" type="text" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="活动地区" prop="shengshiqu">
                                <el-cascader v-model="reqadata.shengshiqu" :options="options" @change="handleChange"></el-cascader>
                            </el-form-item>
                            <el-form-item label="指导老师" prop="teacher">
                                <el-input v-model="reqadata.teacher" type="text" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="年级" prop="grade">
                                <el-input v-model="reqadata.grade" type="text" />
                            </el-form-item>
                            <el-form-item label="班级" prop="class">
                                <el-input v-model="reqadata.class" type="text" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="QQ" prop="qq">
                                <el-input v-model="reqadata.qq" type="text" />
                            </el-form-item>
                            <el-form-item label="微信" prop="weixin">
                                <el-input v-model="reqadata.weixin" type="text" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <div class="footer">
                <button @click="closebtn">取消</button>
                <button @click="submit">报名</button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        msg: {
            type: Array
        }
    },
    data() {
        let checkPhone = (rule, value, callback) => {
            if (value) {
                const reg = /^1[3|4|5|7|8|9][0-9]\d{8}$/;
                if (reg.test(value)) {
                    callback();
                } else {
                    return callback(new Error("请输入正确的手机号"));
                }
            } else {
                return callback();
            }
        };
        return {
            options: [],
            cardoptions: [{
                value: '选项1',
                label: '身份证号'
            }],
            value: '',
            cardvalue: '',
            reqadata: {
                activity_id: '',
                acname: '',
                name: '',
                mobile: '',
                credential_type: '1',
                credential_code: '',
                province_id: '',
                city_id: '',
                county_id: '',
                schoole: '',
                teacher: '',
                grade: '',
                class: '',
                qq: '',
                weixin: '',
                cardvalue: '',
                shengshiqu: []
            },
            grades: [],
            ruleForm: {

                name: [
                    { required: true, message: '请输入姓名', trigger: 'blur' },
                    { min: 1, max: 50, message: '姓名最大50位', trigger: 'blur' }
                ],
                mobile: [
                    { required: true, message: '请输入联系方式', trigger: 'blur' },
                    { validator: checkPhone, trigger: "blur" }
                ],
                cardvalue: [
                    { required: true, message: '请选择证件类型', trigger: 'change' }
                ],
                credential_code: [
                    { required: true, message: '请输入证件号', trigger: 'blur' },
                    { min: 1, max: 50, message: '证件号最大50位', trigger: 'blur' }
                ],
                schoole: [
                    { required: true, message: '请输入学校', trigger: 'blur' },
                    { min: 1, max: 50, message: '学校最大50位', trigger: 'blur' }
                ],
                teacher: [
                    { required: true, message: '请输入指导老师', trigger: 'blur' },
                    { min: 1, max: 20, message: '指导老师最大20位', trigger: 'blur' }
                ],
                grade: [
                    { required: true, message: '请输入年级', trigger: 'blur' },
                    { min: 1, max: 10, message: '年级最大10位', trigger: 'blur' }
                ],
                class: [
                    { required: true, message: '请输入班级', trigger: 'blur' },
                    { min: 1, max: 20, message: '班级最大20位', trigger: 'blur' }
                ],
                qq: [
                    { required: true, message: '请输入qq', trigger: 'blur' },
                    { min: 1, max: 20, message: 'qq最大20位', trigger: 'blur' }
                ],
                weixin: [
                    { required: true, message: '请输入微信', trigger: 'blur' },
                    { min: 1, max: 30, message: '微信最大20位', trigger: 'blur' }
                ]
            }
        }
    },
    mounted() {
        this.reqadata.activity_id = this.msg.id
        this.reqadata.acname = this.msg.name
    },
    methods: {
        closebtn() {
            this.$emit('issue', false)
        },
        handleChange(value) {
            this.reqadata.province_id = value[0]
            this.reqadata.city_id = value[1]
            this.reqadata.county_id = value[2]
            this.reqadata.shengshiqu = value
        },
        handleChange2(value) {

        },
        submit() {
            if (!this.reqadata.name.length) {
                this.$message.error('请输入姓名');
                return false
            }
            this.$refs['ruleForm'].validate(async (valid) => {
                if (valid) {
                    await this.$API.activity.apply.post(this.reqadata).then(res => {
                        this.$message.success('报名成功!');
                        this.$emit('issue', false)
                    })
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.login-div {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .5);
    top: 0;
    display: flex;
    justify-content: center;
    left: 0;
    z-index: 999;
    align-items: center
}

.content-div {
    width: 700px;
    ;
    border-radius: 10px;
    overflow: hidden;
    background: #fff;
}

.content-title {
    height: 60px;
    background-color: #f5f7fa;
    padding-left: 25px;
    position: relative;
}

.form-content {
    padding: 0 55px;
    margin-top: 55px;
    margin-bottom: 55px;
    background: #fff;
}

.upload-div {
    margin-left: 55px;
}

.upload-pic {
    width: 100px;
    height: 100px;
    border-radius: 10px
}

.title-div {
    width: 80px;
    ;
    text-align: right;
    flex-shrink: 0;
}

.form-list {
    height: 400px;
    overflow: auto;
}

.closebtn {
    position: absolute;
    right: 25px;
    top: 16px;
    cursor: pointer;
    width: 26px;
}

.footer {
    height: 60px;
    background: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer button {
    padding: 6px 30px;
    font-size: 12px;
    margin: 0 10px;
    border: 0 !important;
    background: #ccc;
    border-radius: 5px;
    cursor: pointer;
}

.footer button:last-child {
    background: #fe8c00;
    color: #fff;
}
</style>