/* eslint-disable */
const DEFAULT_CONFIG = {
	//标题
	APP_NAME: "门户网站",
	//接口地址
	API_URL: "/api",
	//语言
	LANG: 'zh-cn',
	//请求超时
	TIMEOUT: 10000,
	//TokenName
	TOKEN_PC_NAME: "Authorization",
	//Token前缀
	TOKEN_PC_PREFIX: "Bearer ",
	//追加其他头
	HEADERS: {},
}
export default DEFAULT_CONFIG
/* eslint-enable */